import { Container, Row, Col } from "react-bootstrap";
import styles from "./style.module.scss";
import Logo from "../../assets/images/x360Logo.png";

const Header = () => {
    return <header>
        <Container fluid className={styles.headerInnerBlock}>
            <Row>
                <Col lg={12} className={styles.headerCol}>
                    <img src={Logo} alt="X360 Digital" />
                </Col>
                {/* <Col lg={6} className={styles.headerCol}>
                    <h1>Portfolio</h1>
                </Col> */}
            </Row>
        </Container>
    </header>
}

export default Header;