import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "./components";
import { Col, Container, Row } from "react-bootstrap";
import LeelaMobile from "./assets/images/leelaMobile.png";
import LeelaLogo from "./assets/images/leelaLogo.png";
import SpectrumMobile from "./assets/images/spectrumMobile.png";
import SpectrumLogo from "./assets/images/spectrumLogo.png";
import DentalLogo from "./assets/images/dentalroomLogo.png";
import DentalMobile from "./assets/images/dentalMobile.png";


function App() {
  return (
    <div className="App">
      <Header />
      <div className="portfolioContainer">
        <Container>
          <h2 style={{marginBlockEnd: '10rem'}}>
            <span>OUR</span> PORTFOLIO
          </h2>

          <Row className="portfolioRow">
            <Col lg={4} className="portfolioPointers">
              <ul>
                <li>WEBSITE DESIGN</li>
                <li>DEVELOPMENT</li>
                <li>MARKETING STRATEGY</li>
                <li>SEO & PPCA</li>
                <li>REPUTATION MGMT.</li>
              </ul>
            </Col>
            <Col lg={4} className="portfolioImg">
              <img src={LeelaMobile} alt="Leela Eco Spa" />
            </Col>
            <Col lg={4} className="portfolioGoals">
              <div className="goalsContainer leela">
                <div className="clientLogo">
                  <img src={LeelaLogo} alt="Leela eco spa" />
                </div>
                <p>
                  Started in 2014 with one location, with continuous growth year
                  over year, now have 8 Locations in Calgary and one in Oakville
                </p>
                <ul>
                  <li>
                    SEO (Google Search Optimization): More than 30+ High Volume
                    keywords on first page of Google
                  </li>
                  <li>
                    Reputation Management : 1200+{" "}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          {/* <h2>
            <span>Spectrum</span> Physiotherapy
          </h2> */}

          <Row className="portfolioRow">
            <Col lg={4} className="portfolioPointers">
              <ul>
                <li>WEBSITE DESIGN</li>
                <li>DEVELOPMENT</li>
                <li>MARKETING STRATEGY</li>
                <li>SEO & PPCA</li>
                <li>REPUTATION MGMT.</li>
              </ul>
            </Col>
            <Col lg={4} className="portfolioImg">
              <img src={SpectrumMobile} alt="Leela Eco Spa" />
            </Col>
            <Col lg={4} className="portfolioGoals">
              <div className="goalsContainer spectrum">
                <div className="clientLogo">
                  <img src={SpectrumLogo} alt="Leela eco spa" />
                </div>
                <p>
                Started with one location in Country Hills, Now opening one more location in Nolan Hills
                </p>
                <ul>
                  <li>
                  SEO (Google Search Optimization): More than 20+ High Volume keywords on first page of Google
                  </li>
                  <li>
                  Google Ads: High Conversion Keywords with optimized bidding and Conversion.
                  </li>
                  <li>
                  Website Design and Development
                  </li>
                  <li>
                    Reputation Management : 180+{" "}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          {/* <h2>
            <span>The Dental</span> Room
          </h2> */}

          <Row className="portfolioRow">
            <Col lg={4} className="portfolioPointers">
              <ul>
                <li>WEBSITE DESIGN</li>
                <li>DEVELOPMENT</li>
                <li>MARKETING STRATEGY</li>
                <li>SEO & PPCA</li>
                <li>FACEBOOK ADS</li>
              </ul>
            </Col>
            <Col lg={4} className="portfolioImg">
              <img src={DentalMobile} alt="Leela Eco Spa" />
            </Col>
            <Col lg={4} className="portfolioGoals">
              <div className="goalsContainer dental">
                <div className="clientLogo">
                  <img src={DentalLogo} alt="Leela eco spa" />
                </div>
                <p>
                Working on building their online presence. They have new Downtown location with no online visibility at all. Now have 200% increase in online traffic and appointments.
                </p>
                <ul>
                  <li>
                  SEO (Google Search Optimization): More than 10+ High Volume keywords on first page of Google
                  </li>
                  <li>
                  Google Ads: High Conversion Keywords with optimized bidding and Conversion.
                  </li>
                  <li>
                  Facebook Ads: Created brand buzz and started getting qualified leads
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
